import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export default function KycAdminSidebar() {
  const history = useHistory();

  return (
    <div className="border-l flex flex-col  border-r overflow-hidden">
      <div className="flex justify-between border-b-2 p-4 items-center">
        <span>Admin Dashboard</span>
        <i class="fas fa-bars"></i>
      </div>
      <div className="flex flex-col justify-between m-1 md:m-2 text-sm p-4">
        <span className="text-gray-al-100 mb-2 text-xs flex flex-wrap">
          KYC Management
        </span>
        <span
          className="rounded-full bg-blue-100 flex justify-center p-2 text-xs cursor-pointer"
          onClick={(e) => history.push("/admin/dashboard")}
        >
          View Requests
        </span>
      </div>
    </div>
  );
}
