import React, { useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [warning, setWarning] = useState(false);

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let res = re.test(String(email).toLowerCase());
    if (res !== true) setWarning(true);
    else setWarning(false);
    return res;
  }

  const history = useHistory();

  let handleClick = async () => {
    setWarning(false);
    const isEmailValid = validateEmail(email);

    if (isEmailValid) {
      await axios
        .post("https://webdev.milindsharma.com/user/forgotPassword", {
          email,
        })
        .then((res) => {
          localStorage.setItem("forgotPasswordOtpToken", res?.data?.token);
          history.push("/forgotPassword/otp");
          console.log({ res });
        })
        .catch((err) => {
          if (err.response) {
            setWarning(err?.response?.data?.error);
            console.log(err.response.data);
          }
        });
    }
  };

  return (
    <>
      <div className="flex h-full w-full">
        <div className="w-1/2  h-screen hidden sm:flex">
          <img
            className=" w-full object-cover"
            src="https://source.unsplash.com/800x748/?buildings"
            alt=""
          />
        </div>
        <div
          className="w-1/2 m-4  overflow-scroll no-scrollbar h-full"
          style={{ height: "90vh" }}
        >
          <div className="flex h-1/4">
            <img
              className="md:w-32 w-24 "
              src="https://altiusinvestech.com/photos/logo.png"
              alt="Altius Logo"
            />
          </div>

          <div
            className="flex flex-col h-3/4 w-full justify-around"
            style={{ height: "90vh" }}
          >
            <div>
              <h1 className="font-bold text-xl text-gray-700">
                Forgot Password ?
              </h1>
              <p className="text-gray-500">Hello, Welcome back!</p>
            </div>
            <div className=" flex flex-col w-full mr-4">
              <input
                className="outline-none md:py-6 py-4 border-b focus:border-blue-700"
                type="email"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />

              <div className="flex flex-col justify-between items-center">
                {warning && <div className="text-red-500 w-min">{warning}</div>}
                <button
                  className="flex items-center p-2 mt-8 bg-blue-700 text-white justify-center text-center border google-btn rounded-full px-12 bg-gray-200 shadow focus:outline-none"
                  onClick={handleClick}
                >
                  Send OTP
                </button>
              </div>
            </div>
            <Link to="/login" className="text-blue-600">
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
