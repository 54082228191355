import React, { useState, useEffect } from "react";
import { GoVerified } from "react-icons/go";
import { useHistory, useRouteMatch } from "react-router-dom";
import axios from "axios";
import { useLogin } from "../../contexts/LoginContext";

export default function BasicProfile({ setPageNumber }) {
  const [resObj, setResObj] = useState({});
  const [name, setName] = useState("");
  const [panNumber, setPanNumber] = useState(
    resObj?.data?.basic_details?.pan_number || ""
  );
  const [address, setAddress] = useState("");
  const [warning, setWarning] = useState("");
  setPageNumber(0);

  const { token } = useLogin();
  let history = useHistory();

  function validatePan(panNumber) {
    const re = /\b[A-Z0-9]{10}\b/;
    let res = re.test(panNumber);
    if (res !== true) setWarning("Pan Number must be 10 digit alpha-numeric");

    return res;
  }

  function validateAddress(address) {
    if (address !== "") return true;
    else {
      setWarning("Please enter a valid address");
      return false;
    }
  }

  function validateName(name) {
    if (name !== "") return true;
    else {
      setWarning("Please enter a valid address");
      return false;
    }
  }

  let handleClick = async () => {
    const isAddressValid = validateAddress(address);
    const isPanValid = validatePan(panNumber);
    const isNameValid = validateName(name);

    if (isPanValid && isNameValid && isAddressValid) {
      setWarning(false);
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const bodyParameters = {
        name,
        address,
        pan_number: panNumber,
      };
      console.log("basic", token);
      await axios
        .post(
          "https://webdev.milindsharma.com/user/kyc/updateBasicDetails",
          bodyParameters,
          config
        )
        .then((res) => {
          console.log(res);
          history.push(`/kyc/dematDetails`);
        })
        .catch((err) => setWarning(err?.response?.error));
    }
  };

  useEffect(() => {
    (async function () {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        let res = await axios.get(
          "https://webdev.milindsharma.com/user/kyc",
          config
        );
        console.log(res);
        setResObj(res);
        setName(res?.data?.basic_details?.name || "");
        setPanNumber(res?.data?.basic_details?.pan_number || "");
        setAddress(res?.data?.basic_details?.address || "");
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div className="flex flex-col justify-around h-full">
      <div className="text-xl  font-medium text-blue-al">
        Your Basic Profile
      </div>
      <div className="overflow-scroll no-scrollbar">
        <div className="flex flex-col">
          <span className="text-gray-500 text-custom-gray-200 pb-4">
            Contact Information
          </span>

          <label className="text-sm text-custom-gray-100" htmlFor="emailId">
            Email Id
          </label>
          <div className="flex items-center ">
            <input
              className="border-2 rounded px-2 w-4/6 py-1 my-1 mb-2 focus:outline-none"
              type="email"
              id="emailId"
              value={resObj?.data?.email || ""}
              disabled={!resObj?.data?.email_verified === 0}
              readOnly={!resObj?.data?.email_verified === 0}
            ></input>
            <div className="text-green-al ml-4 flex items-center">
              Verified <GoVerified className="ml-2" />
            </div>
          </div>

          <label
            className="text-sm text-custom-gray-100"
            htmlFor="mobileNumber"
          >
            Mobile Number
          </label>
          <div className="flex items-center">
            <input
              className="border-2 rounded px-2 w-4/6 py-1 my-1 mb-2 focus:outline-none"
              type="number"
              id="mobileNumber"
              value={resObj?.data?.mobile || ""}
              disabled={!resObj?.data?.mobile_verified === 0}
              readOnly={!resObj?.data?.mobile_verified === 0}
            ></input>
            {resObj?.data?.mobile_verified === 0 ? (
              <div className="ml-4 w-30 overflow-scroll text-custom-blue no-scrollbar">
                Verify With OTP
              </div>
            ) : (
              <div className="text-green-al ml-4 flex items-center">
                Verified <GoVerified className="ml-2" />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col">
          <span className="text-gray-500 text-custom-gray-200 pb-4">
            Basic Information
          </span>
          <label className="text-sm text-custom-gray-100" htmlFor="fullName">
            Full Name
          </label>
          <input
            className="border-2 rounded px-2 w-4/6 py-1 my-1 mb-2 focus:outline-none"
            type="text"
            id="fullName"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
          <label className="text-sm text-custom-gray-100" htmlFor="panNumber">
            PAN Number (As Per PAN Card)
          </label>
          <input
            className="border-2 rounded px-2 w-4/6 py-1 my-1 mb-2 focus:outline-none"
            type="text"
            id="panNumber"
            value={panNumber}
            onChange={(e) => setPanNumber(e.target.value)}
          ></input>
          <label className="text-sm text-custom-gray-100" htmlFor="address">
            Address (As per address proof)
          </label>
          <textarea
            className="border-2 rounded px-2 w-4/6 py-1 my-1 mb-2 focus:outline-none h-20 no-scrollbar resize-none"
            type="text"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          ></textarea>
        </div>
      </div>
      <div>
        {warning && <div className="text-red-500 mb-2">{warning}</div>}
        <button
          className="border bg-blue-al rounded-lg items-center justify-center text-white px-4 py-2 cursor-pointer focus:outline-none shadow mt-2"
          onClick={handleClick}
        >
          Next Step &gt;
        </button>
      </div>
    </div>
  );
}
