import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLogin } from "../contexts/LoginContext";

const headingClasslist =
  "overflow-visible border-b border-gray-500 h-4 mb-4 text-gray-al-100 flex justify-between items-center";
const spanClasslist = "bg-white px-1 font-bold";
const uploadClasslist = "md:w-1/5 w-1/4 h-40 flex flex-col bg-gray-100 border";
const classList = "w-5/12 flex flex-col mt-2";
const inputClassList = "p-1 border-2 border-gray-200 rounded mt-2";
const labelList = "text-gray-600 font-medium";
const buttonClass = "bg-blue-al text-white rounded-lg p-1 px-3";

const BasicProfile = ({ name, address, pan, email, mobile, setShowStep }) => {
  return (
    <div className="flex flex-col justify-between h-1/4 text-sm 	 ">
      <div className={headingClasslist}>
        <div className={spanClasslist}>Your Basic Profile</div>
        <Link to="/kyc/basicProfile">
          <button className={buttonClass} onClick={() => setShowStep(true)}>
            Edit
          </button>
        </Link>
      </div>
      <div className="flex flex-wrap justify-between items-between">
        <div className={classList}>
          <label className={labelList}>Mobile Number</label>
          <input
            value={mobile || ""}
            className={inputClassList}
            disabled
          ></input>
        </div>
        <div className={classList}>
          <label className={labelList}>Email</label>
          <input
            value={email || ""}
            className={inputClassList}
            disabled
          ></input>
        </div>

        <div className={classList}>
          <label className={labelList}>Full Name</label>
          <input value={name || ""} className={inputClassList}></input>
        </div>
        <div className={classList}>
          <label className={labelList}>PAN</label>
          <input
            placeholder="Enter PAN card number"
            className={inputClassList}
            value={pan || ""}
          ></input>
        </div>
        <div className="w-full flex flex-col mt-2 ">
          <label className={labelList}>Address</label>
          <input
            placeholder="Enter address here"
            className={inputClassList}
            value={address || ""}
          ></input>
        </div>
      </div>
    </div>
  );
};

const DematDetails = ({ dpId, clientId, setShowStep }) => {
  return (
    <div className="flex flex-col justify-between h-1/4 text-sm mt-4	 ">
      <div className={headingClasslist}>
        <span className={spanClasslist}>Demat Details</span>
        <Link to="/kyc/dematDetails">
          {" "}
          <button className={buttonClass} onClick={() => setShowStep(true)}>
            Edit
          </button>
        </Link>
      </div>
      <div className="flex flex-wrap justify-between items-between">
        <div className={classList}>
          <label className={labelList}>DP Id </label>
          <input
            placeholder="Enter DIP Id"
            value={dpId || ""}
            className={inputClassList}
          ></input>
        </div>
        <div className={classList}>
          <label className={labelList}>Client Id</label>
          <input value={clientId || ""} className={inputClassList}></input>
        </div>
      </div>
    </div>
  );
};
const BankDetails = ({ bankName, ifscCode, accountNumber, setShowStep }) => {
  return (
    <div className="flex flex-col justify-between h-1/4 text-sm mt-4">
      <div className={headingClasslist}>
        <span className={spanClasslist}>Bank Account Details</span>
        <Link to="/kyc/bankDetails">
          {" "}
          <button className={buttonClass} onClick={() => setShowStep(true)}>
            Edit
          </button>
        </Link>
      </div>
      <div className="flex flex-wrap justify-between items-between">
        <div className={classList}>
          <label className={labelList}>Bank Name</label>
          <input
            placeholder="Enter Bank name"
            value={bankName || ""}
            className={inputClassList}
          ></input>
        </div>
        <div className={classList}>
          <label className={labelList}>Bank Account Number</label>
          <input
            placeholder="Enter Bank Account number"
            className={inputClassList}
            value={accountNumber || ""}
          ></input>
        </div>
        <div className={classList}>
          <label className={labelList}>IFSC code</label>
          <input
            placeholder="Enter IFSC code"
            className={inputClassList}
            value={ifscCode || ""}
          ></input>
        </div>
      </div>
    </div>
  );
};

const Docpic = ({ documentName }) => {
  const [document, setDocument] = useState("");
  const { token } = useLogin();

  useEffect(() => {
    (async () => {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        let res = await axios.get(
          `https://webdev.milindsharma.com/user/kyc/document/${documentName}`,
          config
        );
        setDocument(res?.data);
      } catch (err) {
        console.log("error", err?.response?.data?.error);
      }
    })();
  }, []);

  return (
    <div className="flex justify-center items-center cursor-pointer p-2">
      <a
        className="w-full h-40 flex justify-center items-center flex-col"
        href={document}
        download
      >
        <i className="fa fa-download fa-2x text-center text-gray-500 mt-1 mb-1"></i>
        <span className="text-gray-600 text-xs">{`Click to download ${documentName}`}</span>
      </a>
    </div>
  );
};
const UploadDocument = ({ setShowStep }) => {
  return (
    <div className="flex flex-col justify-between h-1/4 text-sm mt-4">
      <div className={headingClasslist}>
        <span className={spanClasslist}>Upload Documents</span>
        <Link to="/kyc/uploadDocuments">
          <button className={buttonClass} onClick={() => setShowStep(true)}>
            Edit
          </button>
        </Link>
      </div>
      <div className="flex flex-wrap justify-between items-between">
        <div className={uploadClasslist}>
          <Docpic documentName="pan_card" />
        </div>
        <div className={uploadClasslist}>
          <Docpic documentName="address_proof" />
        </div>
        <div className={uploadClasslist}>
          <Docpic documentName="bank_proof" />
        </div>
        <div className={uploadClasslist}>
          <Docpic documentName="demat_proof" />
        </div>
      </div>
    </div>
  );
};

export default function Details({ resObj, setShowStep }) {
  console.log("taddaaa", resObj);
  return (
    <div className="flex flex-col justify-around">
      <div className="text-gray-al-100 font-medium text-xl">
        Review {"&"} Submit
      </div>
      <div className="text-gray-500 text-sm mb-8">
        Review your details and submit
      </div>
      <BasicProfile
        name={resObj?.basic_details?.name}
        address={resObj?.basic_details?.address}
        pan={resObj?.basic_details?.pan_number}
        email={resObj?.email}
        mobile={resObj?.mobile}
        setShowStep={setShowStep}
      />
      <DematDetails
        dpId={resObj?.demat_details?.dp_id}
        clientId={resObj?.demat_details?.client_id}
        setShowStep={setShowStep}
      />
      <BankDetails
        bankName={resObj?.bank_details?.bank_name}
        ifscCode={resObj?.bank_details?.ifsc_code}
        accountNumber={resObj?.bank_details?.account_number}
        setShowStep={setShowStep}
      />
      <UploadDocument setShowStep={setShowStep} />
    </div>
  );
}
