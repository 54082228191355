import React from "react";
import axios from "axios";
import { createContext, useContext, useState } from "react";

export const AdminLoginContext = createContext();

function adminLoginService(username, password) {
  return axios.post("https://webdev.milindsharma.com/admin/login", {
    email: username,
    password,
  });
}

export function AdminLoginProvider({ children }) {
  const { isAdminLoggedIn, token: savedToken } =
    JSON.parse(localStorage?.getItem("adminLogin")) || {};

  const [adminLogin, setAdminLogin] = useState(isAdminLoggedIn);
  const [adminToken, setAdminToken] = useState(savedToken);
  const [adminLoginError, setAdminLoginError] = useState("");

  function loginAdminWithToken(token) {
    setAdminLogin(true);
    setAdminToken(token);
    console.log({ token });
    localStorage.setItem(
      "adminLogin",
      JSON.stringify({ isAdminLoggedIn: true, token })
    );
  }

  async function loginAdminWithCredentials(username, password) {
    try {
      console.log("in login Credentials", username, password);
      const res = await adminLoginService(username, password);
      console.log({ res });
      if (res.status === 200) {
        loginAdminWithToken(res.data.token);
      }
    } catch (err) {
      console.error(err?.response?.data);
      setAdminLoginError(err?.response?.data.error);
    }
  }

  function adminLogout() {
    localStorage.removeItem("adminLogin");
    setAdminLogin(false);
    setAdminToken(null);
  }

  return (
    <AdminLoginContext.Provider
      value={{
        adminLogin,
        loginAdminWithCredentials,
        setAdminLogin,
        adminLogout,
        adminToken,
        adminLoginError,
        loginAdminWithToken,
      }}
    >
      {children}
    </AdminLoginContext.Provider>
  );
}

export function useAdminLogin() {
  return useContext(AdminLoginContext);
}
