import React, { useEffect, useState } from "react";
import { useLogin } from "../contexts/LoginContext";
import ReviewDetails from "./ReviewAndSubmitDetails";
import TermsConditions from "./TermsConditions";
import axios from "axios";

export default function ReviewSubmit({ setPageNumber, setShowStep }) {
  const [resObj, setResObj] = useState({});
  const { token } = useLogin();
  setPageNumber(4);

  useEffect(() => {
    (async function () {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        let res = await axios.get(
          "https://webdev.milindsharma.com/user/kyc",
          config
        );
        console.log(res);
        setResObj(res);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div className="w-full overflow-scroll no-scrollbar flex md:flex-row flex-col md:ml-4">
      <div className="md:w-3/4  m-2">
        <ReviewDetails resObj={resObj?.data} setShowStep={setShowStep} />
      </div>
      <TermsConditions resObj={resObj?.data} />
    </div>
  );
}
