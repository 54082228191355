import React, { useState, useEffect } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { useAdminLogin } from "../contexts/AdminLoginContext";

export default function LoginPage() {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const {
    adminLogin,
    loginAdminWithCredentials,
    adminLogout,
    adminLoginError,
  } = useAdminLogin();

  const history = useHistory();

  let handleClick = async () => {
    console.log("jiyuew", userId, password);
    if (!adminLogin) {
      await loginAdminWithCredentials(userId, password);
    }
  };

  useEffect(() => {
    if (adminLogin) history.push("/admin/dashboard");
  }, [adminLogin]);

  return (
    <div className="h-screen  flex  ">
      <div className="w-1/2 md:flex hidden">
        <img
          className="h-full w-full"
          src="https://source.unsplash.com/800x748/?buildings"
          alt=""
        />
      </div>
      <div
        className="md:w-1/2 w-full flex flex-col justify-between m-4"
        style={{ height: "60vh" }}
      >
        <div className="">
          <img
            className="md:w-32 w-24"
            src="https://altiusinvestech.com/photos/logo.png"
            alt="Altius Logo"
          />
        </div>
        <div>
          <h1 className="font-bold text-xl text-gray-700">
            Admin Login Account
          </h1>
          <p className="text-gray-500">Hello, Welcome back!</p>
        </div>
        <div className="">
          <input
            type="text"
            name="userId"
            id="userId"
            placeholder="User Id"
            required
            className="outline-none md:py-6 w-full py-4 border-b focus:border-blue-700"
            onChange={(e) => setUserId(e.target.value)}
          />

          <input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            required
            className="outline-none md:py-6 w-full py-4 border-b focus:border-blue-700"
            onChange={(e) => setPassword(e.target.value)}
          />

          {adminLoginError !== "" && (
            <div className="text-red-500 mt-2">{adminLoginError}</div>
          )}
          <div className="flex justify-between  text-blue-600 items-center mt-4">
            <button
              className="flex items-center p-2  bg-blue-700 text-white justify-center text-center border google-btn rounded-full px-12 bg-gray-200 shadow focus:outline-none"
              onClick={handleClick}
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
