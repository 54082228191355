import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import axios from "axios";

export default function BankDetails({ setPageNumber }) {
  const [warning, setWarning] = useState(false);
  const [bankName, setBankName] = useState(undefined);
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const inputClassList =
    "border-2 rounded px-2 w-4/6 py-1 my-1 mb-2 focus:outline-none";
  const { token } = useLogin();

  let history = useHistory();
  setPageNumber(2);

  function validateIFSC(ifscCode) {
    const re = /\b[a-zA-Z0-9]{11}\b/;
    let res = re.test(ifscCode);
    if (res !== true) setWarning("IFSC code must be 11 digit alpha-numeric");

    return res;
  }

  function validateBankAccountNumber(bankAccountNumber) {
    const re = /\b[0-9]{8,20}\b/;
    let res = re.test(bankAccountNumber);
    if (res !== true) setWarning("Please Enter a valid Account Number");

    return res;
  }

  function validateBankName() {
    const res = bankName !== "" && bankName.length < 100;
    if (res !== true) setWarning("Please Enter a valid Bank Name");

    return res;
  }

  let handleClick = async () => {
    const isIFSCValid = validateIFSC(ifscCode);
    const isBankAccountNumberValid =
      validateBankAccountNumber(bankAccountNumber);
    const isBankNameValid = validateBankName();
    console.log("bankName", bankName);
    console.log(isIFSCValid, isBankAccountNumberValid, isBankNameValid);

    if (isIFSCValid && isBankNameValid && isBankAccountNumberValid) {
      setWarning(false);
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const bodyParameters = {
        bank_name: bankName,
        account_number: bankAccountNumber,
        ifsc_code: ifscCode,
      };
      console.log(bankName);

      await axios
        .post(
          "https://webdev.milindsharma.com/user/kyc/updateBankDetails",
          bodyParameters,
          config
        )
        .then((res) => {
          history.push(`/kyc/uploadDocuments`);
          console.log({ res });
        })
        .catch((err) => setWarning(err.response.data.error));
    }
  };

  useEffect(() => {
    (async function () {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        let res = await axios.get(
          "https://webdev.milindsharma.com/user/kyc",
          config
        );
        console.log(res);
        setBankName(res?.data?.bank_details?.bank_name || "");
        setBankAccountNumber(res?.data?.bank_details?.account_number || "");
        setIfscCode(res?.data?.bank_details?.ifsc_code || "");
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div className="flex flex-col flex-1 h-full ml-5 overflow-scroll no-scrollbar">
      <div className="text-xl font-medium text-blue-al">
        Bank Account Details
      </div>

      <div className="flex flex-col">
        <label className="text-sm mt-24 mb-2" htmlFor="bankName">
          Bank Name
        </label>
        <input
          className={inputClassList}
          type="text"
          id="bankName"
          value={bankName}
          onChange={(e) => setBankName(e.target.value)}
        ></input>
        <label className="text-sm mt-2 mb-2" htmlFor="accountNumber">
          Bank Account Number
        </label>
        <input
          className={inputClassList}
          type="number"
          id="accountNumber"
          value={bankAccountNumber}
          onChange={(e) => setBankAccountNumber(e.target.value)}
        ></input>
        <label className="text-sm mt-2 mb-2" htmlFor="ifsc">
          IFSC code
        </label>
        <input
          className={inputClassList}
          type="text"
          id="ifsc"
          value={ifscCode}
          onChange={(e) => setIfscCode(e.target.value)}
        ></input>
      </div>

      <div className="mt-4">
        {warning && <div className="text-red-500 mb-2">{warning}</div>}
        <button
          className="border bg-blue-al rounded-lg items-center justify-center text-white px-4 py-2 cursor-pointer focus:outline-none shadow"
          onClick={handleClick}
        >
          Next Step &gt;
        </button>
      </div>
    </div>
  );
}
