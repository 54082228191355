import React, { useState, useEffect } from "react";
import axios from "axios";
// import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function SignUpTwo() {
  const [otp, setOtp] = useState(0);
  const [otpWarning, setOtpWarning] = useState(false);
  const [counter, setCounter] = useState(0);
  const resendTimeInSeconds = 120;

  const history = useHistory();
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  let handleClick = async () => {
    if (otp === 0) {
      setOtpWarning("Please enter your OTP");
    }

    if (counter <= 0 && otp !== 0) {
      setOtpWarning(false);
      let token = localStorage.getItem("basicDetailToken");
      console.log(token);
      await axios
        .post(
          "https://webdev.milindsharma.com/user/registration/verifyRegistrationOTP",
          {
            token: token,
            otp: otp,
          }
        )
        .then((res) => {
          localStorage.setItem("otpToken", res.data.token);
          history.push("/signup/saveUserPassword");
          console.log({ res });
        })
        .catch((err) => {
          if (err.response) {
            setOtpWarning(err?.response?.data?.error);
            console.log(err.response.data);
          }
        });
    }
  };

  let resendOtpHandler = async () => {
    if (counter <= 0) {
      let token = localStorage.getItem("basicDetailToken");
      setCounter(resendTimeInSeconds);
      console.log(token);
      await axios
        .post(
          "https://webdev.milindsharma.com/user/registration/resendRegistrationOTP",
          {
            token: token,
          }
        )
        .then((res) => {
          console.log({ res });
        })
        .catch((err) => {
          if (err.response) {
            // setOtpWarning(err?.response?.data?.error);
            console.log(err.response.data);
          }
        });
    }
  };

  return (
    <>
      <div className=" flex flex-col justify-around  m-8 mb-32 h-screen  overflow-scroll no-scrollbar ">
        <div className="flex">
          <img
            className="md:w-32 w-24 "
            src="https://altiusinvestech.com/photos/logo.png"
            alt="Altius Logo"
          />
        </div>

        <div className="w-full flex flex-col justify-around mr-4">
          <input
            className="outline-none md:py-6 py-4 border-b focus:border-blue-700"
            inputmode="numeric"
            type="number"
            placeholder="Enter your OTP"
            onChange={(e) => setOtp(e.target.value)}
          />

          <div className="flex flex-col justify-between items-center">
            {otpWarning && (
              <div className="text-red-500 w-min">{otpWarning}</div>
            )}
            <button
              className="flex items-center p-2 mt-8 bg-blue-700 text-white justify-center text-center border google-btn rounded-full px-12 bg-gray-200 shadow focus:outline-none"
              onClick={handleClick}
            >
              Verify OTP
            </button>
          </div>
          <div
            className={`${
              counter > 0 ? "disabled text-gray-al-200" : "text-blue-al"
            } cursor-pointer  mt-2 `}
            onClick={resendOtpHandler}
          >
            <span>{`Resend OTP ${
              counter > 0 ? `(${counter} secs)` : ""
            }`}</span>
          </div>
        </div>
      </div>
    </>
  );
}
