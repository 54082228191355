import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "email", label: "Email", minWidth: 300 },
  {
    id: "phone",
    label: "Phone No.",
    minWidth: 170,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "remarks",
    label: "Remarks",
    minWidth: 170,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    format: (value) => value.toFixed(2),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: "80vw",
  },
  container: {
    maxHeight: 440,
  },
});

export default function KycAdminReviewTable({ users }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const history = useHistory();

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (event) => {
    let target = event.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter(
            (x) =>
              x?.name?.toLowerCase().includes(target.value.toLowerCase()) ||
              x?.email?.toLowerCase().includes(target.value.toLowerCase()) ||
              x?.phone?.toLowerCase().includes(target.value.toLowerCase()) ||
              x?.remarks?.toLowerCase().includes(target.value.toLowerCase()) ||
              x?.status?.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
  };

  return (
    <>
      <div className="w-full float-right items-center p-4">
        <input
          className="focus:outline-none float-right text-sm rounded p-2 px-3 border"
          placeholder="Search"
          onChange={handleSearch}
        ></input>
      </div>
      <Paper className={classes.root}>
        <TableContainer className={`${classes.container} no-scrollbar`}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filterFn
                .fn(users)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      className=" text-xs sm:text-sm md:text-base"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];

                        if (column.id == "action" && row.status !== "initial") {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              onClick={(e) => {
                                history.push(`/admin/reviewKyc/${row.id}`);
                              }}
                            >
                              <span className="text-blue-al cursor-pointer">
                                Review
                              </span>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {
                              /* {column.format && typeof value === "number"
														? column.format(value)
														:}*/ value
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25, 100]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
