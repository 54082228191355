import React from "react";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import profile from "../utils/image/profile.svg";

function getModalStyle() {
  const top = 11;
  const right = 1;
  return {
    top: `${top}%`,
    right: `${right}%`,
  };
}

export default function SimpleModal({ logout }) {
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const listItemClass = "border-b ";
  const itemClass =
    "my-2 w-full flex justify-between items-center cursor-pointer";

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
      <Button className="focus:outline-none" onClick={handleOpen}>
        <img src={profile} className="w-8 h-8 md:w-10 md:h-10" alt="" />
      </Button>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div
          style={modalStyle}
          className="absolute md:w-1/6 w-4/5 px-2 rounded mr-1 bg-white shadow"
        >
          <ul className="flex flex-col justify-around ">
            <div className={listItemClass}>
              <div className={itemClass}>
                <span>Terms and Conditions</span>
                <i className="fas fa-fan text-gray-600"></i>
              </div>
              <div className={itemClass}>
                <span>Privacy Policy</span>
                <i className="fas fa-user-secret text-gray-600"></i>
              </div>
            </div>
            <div className={listItemClass}>
              <div className={itemClass}>
                <span>My Account</span>
                <i className="fas fa-user-alt text-gray-600"></i>
              </div>
              <div className={itemClass}>
                <span>Refer and Earn</span>
                <i className="fas fa-money-bill-wave text-gray-600"></i>
              </div>
            </div>
            <div className={listItemClass}>
              <div className={itemClass}>
                <span>Raise a Ticket</span>
              </div>
            </div>
            <div className={listItemClass}>
              <div className={itemClass}>
                <span>Announcements</span>
              </div>
              <div className={itemClass}>
                <span>Help and Support</span>
              </div>
            </div>
            <div className={listItemClass}>
              <div className={`${itemClass} text-red-600`} onClick={logout}>
                <span>Logout</span>
                <i className="fas fa-sign-out-alt"></i>
              </div>
            </div>
          </ul>
        </div>
      </Modal>
    </span>
  );
}
