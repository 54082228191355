import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import KycAdminReviewTable from "./KycAdminReviewTable";
import KycAdminSidebar from "./KycAdminSidebar";
import axios from "axios";
import { useAdminLogin } from "../contexts/AdminLoginContext";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(4),
  },
  Pagination: {
    color: "white",
    backgroundColor: "blue",
  },
}));

const StatusDropdown = ({ state, setState }) => {
  const classes = useStyles();
  console.log({ state });
  const handleChange = (event) => {
    setState(event.target.value);
  };
  return (
    <div>
      <FormControl className={classes.margin}>
        <NativeSelect onChange={handleChange}>
          <option className="text-center p-4" value="all">
            All
          </option>
          <option className="text-blue-al text-center p-4" value="pending">
            Pending
          </option>
          <option className="text-green-al text-center p-4" value="verified">
            Verified
          </option>
          <option className="text-red-al text-center p-4" value="rejected">
            Rejected
          </option>
          <option className="text-center p-4" value="initial">
            Initial
          </option>
        </NativeSelect>
      </FormControl>
    </div>
  );
};

function createData(name, email, phone, remarks, status, id) {
  return { name, email, phone, remarks, status, id };
}

export default function KycAdminDashboard() {
  const [state, setState] = useState("all");
  const { adminToken } = useAdminLogin();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [res, setRes] = useState([]);

  useEffect(() => {
    (async function () {
      const config = {
        headers: { Authorization: `Bearer ${adminToken}` },
      };
      try {
        let res = await axios.get(
          "https://webdev.milindsharma.com/admin/users",
          config
        );
        setRes(res?.data);
        console.log(res?.data);
      } catch (err) {
        console.log("error", err?.response?.data?.error);
      }
    })();
  }, []);

  useEffect(() => {
    setUsers(
      res.map((item) =>
        createData(
          item?.name,
          item?.email,
          item?.mobile,
          item?.remark,
          item?.status,
          item?.user_id
        )
      )
    );
  }, [res]);

  useEffect(() => {
    if (state === "all") {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(users.filter((user) => user?.status === state));
    }
  }, [state, users]);

  return (
    <div className="m-2 flex flex-col w-3/4">
      <div
        className="w-full  flex justify-between  items-center p-2"
        style={{ height: "15vh" }}
      >
        <div className="text-xl text-gray-al-100">KYC</div>
        <div className="flex justify-between items-center">
          <span className="text-blue-al">Status</span>
          <StatusDropdown state={state} setState={setState} />
        </div>
      </div>

      <div className="mt-4">
        <KycAdminReviewTable users={filteredUsers} />
      </div>
    </div>
  );
}
