import React from "react";
import KycAdminSidebar from "./KycAdminSidebar";
import { green } from "@material-ui/core/colors";
import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useHistory, useParams } from "react-router-dom";
import { useAdminLogin } from "../contexts/AdminLoginContext";
import axios from "axios";

const documentClassList = "w-1/2 overflow-y-scroll";
const verificationClassList =
  "w-full flex md:flex-row flex-col my-4 justify-around items-center";
const verificationParameter = "w-1/2 flex justify-around";

function findVerificationLogo(verificationStatus) {
  if (verificationStatus === "verified") return "fa-check text-green-al";
  else if (verificationStatus === "rejected") return "fa-times text-red-al";
  else return "fa-user-check text-blue-al";
}

const Details = ({
  title,
  dataValue,
  dataId,
  selectedOption,
  onChange,
  lastUpdatedBy,
  lastUpdatedAt,
  lastUpdatedOn,
}) => {
  return (
    <div className="">
      <span className="mb-4 text-gray-al-200">{title}</span>
      <div className="flex flex-col">
        <span className="text-gray-al-100">{dataValue}</span>
        {lastUpdatedBy && (
          <span className="text-gray-al-100 mt-2">{`Last Updated by :- ${lastUpdatedBy?.name}`}</span>
        )}
        <span className="text-gray-al-100 mb-2">{`on ${lastUpdatedOn} ${lastUpdatedAt}`}</span>
        <span className="flex flex-col">
          <label>
            <input
              type="radio"
              name={dataId}
              value="verified"
              checked={selectedOption === "verified"}
              onChange={onChange}
            />{" "}
            Approve
          </label>

          <label>
            <input
              name={dataId}
              type="radio"
              value="rejected"
              checked={selectedOption === "rejected"}
              onChange={onChange}
            />{" "}
            Reject
          </label>
        </span>
      </div>
    </div>
  );
};

export default function ReviewKycRequest() {
  const [user, setUser] = useState({});
  const [nameStatus, setNameStatus] = useState("");
  const [pan, setPan] = useState("");
  const [address, setAddress] = useState("");
  const [bank, setBank] = useState("");
  const [demat, setDemat] = useState("");
  const history = useHistory();

  const [remark, setRemark] = useState("");

  const [addressStatus, setAddressStatus] = useState("");
  const [bankStatus, setBankStatus] = useState("");
  const [dematStatus, setDematStatus] = useState("");
  const [panStatus, setPanStatus] = useState("");
  const [notification, setNotification] = useState("");
  const [error, setError] = useState("");

  const { adminToken } = useAdminLogin();
  const { userId } = useParams();

  const config = {
    headers: { Authorization: `Bearer ${adminToken}` },
  };

  const handleSubmit = async () => {
    setNotification("");
    setError("");
    const bodyParameters = {
      remark,
      user_id: userId,
    };

    try {
      let res = await axios.post(
        `https://webdev.milindsharma.com/admin/kyc/remark`,
        bodyParameters,
        config
      );
      console.log(res?.data);
      setNotification("Remark Submitted Successfully");
    } catch (err) {
      console.log("error", err?.response?.data?.error);
      setError(err?.response?.data?.error);
    }
  };

  const updateStatus = async (updateBlock, field, userId, value) => {
    setNotification("");
    setError("");
    const bodyParameters = {
      status: value,
      user_id: userId,
      field,
    };

    try {
      let res = await axios.post(
        `https://webdev.milindsharma.com/admin/kyc/${updateBlock}`,
        bodyParameters,
        config
      );
      console.log(res?.data);
      setNotification(`${field} updated successfully`);
    } catch (err) {
      console.log("error", err?.response?.data?.error);
      setError(err?.response?.data?.error);
    }
  };

  useEffect(() => {
    (async function () {
      const config = {
        headers: { Authorization: `Bearer ${adminToken}` },
      };
      setError("");
      try {
        let res = await axios.get(
          `https://webdev.milindsharma.com/admin/users/${userId}`,
          config
        );
        console.log(res?.data);
        setUser(res?.data);
        setNameStatus(res?.data?.basic_details?.name_status || "initial");
        setAddressStatus(res?.data?.basic_details?.address_status || "initial");
        setDematStatus(res?.data?.demat_details?.status || "initial");
        setBankStatus(res?.data?.bank_details?.status || "initial");
        setPanStatus(res?.data?.basic_details?.pan_status || "initial");
        setRemark(res?.data?.remark || "");
      } catch (err) {
        console.log("error", err?.response?.data?.error);
        setError(err?.response?.data?.error);
      }

      try {
        let addRes = await axios.get(
          `https://webdev.milindsharma.com/admin/users/document/${userId}/address_proof`,
          config
        );
        setAddress(addRes?.data);
      } catch (err) {
        console.log("error", err?.response?.data?.error);
        setError(err?.response?.data?.error);
      }

      try {
        let panRes = await axios.get(
          `https://webdev.milindsharma.com/admin/users/document/${userId}/pan_card`,
          config
        );
        setPan(panRes?.data);
      } catch (err) {
        console.log("error", err?.response?.data?.error);
        setError(err?.response?.data?.error);
      }

      try {
        let dematRes = await axios.get(
          `https://webdev.milindsharma.com/admin/users/document/${userId}/demat_proof`,
          config
        );
        setDemat(dematRes?.data);
      } catch (err) {
        console.log("error", err?.response?.data?.error);
        setError(err?.response?.data?.error);
      }

      try {
        let bankRes = await axios.get(
          `https://webdev.milindsharma.com/admin/users/document/${userId}/bank_proof`,
          config
        );
        setBank(bankRes?.data);
      } catch (err) {
        console.log("error", err?.response?.data?.error);
        setError(err?.response?.data?.error);
      }
    })();
  }, []);

  return (
    <div className="w-full m-2 flex flex-col">
      <div
        className="w-full  flex justify-between  items-center p-2 sm:text-base text-xs"
        style={{ height: "10vh" }}
      >
        <span>Review KYC Request</span>
      </div>
      <div className="md:w-1/2 w-full flex justify-between items-center  p-2">
        <span>
          <i
            className={`fa ${findVerificationLogo(nameStatus)}`}
            aria-hidden="true"
          ></i>{" "}
          Name
        </span>
        <span>
          <i
            className={`fa ${findVerificationLogo(panStatus)}`}
            aria-hidden="true"
          ></i>{" "}
          PAN
        </span>
        <span>
          <i className={`fas ${findVerificationLogo(addressStatus)}`}></i>{" "}
          Address
        </span>
        <span>
          <i className={`fas ${findVerificationLogo(bankStatus)}`}></i> Bank
        </span>
        <span>
          <i className={`fas ${findVerificationLogo(dematStatus)}`}></i> Demat
        </span>
      </div>
      <div className="w-full overflow-scroll no-scrollbar">
        <div className={verificationClassList}>
          {pan ? (
            <div className={documentClassList} style={{ height: "285px" }}>
              <embed
                width="100%"
                height={pan.includes("image") ? "" : "100%"}
                src={`${pan}`}
              />
            </div>
          ) : (
            <div className={`w-1/2 ${documentClassList}`}> Loading...</div>
          )}
          <div className={verificationParameter}>
            <Details
              dataId="1"
              title="Name Verification"
              dataValue={user?.basic_details?.name}
              selectedOption={nameStatus}
              onChange={(e) => {
                updateStatus(
                  "updateBasicDetailsStatus",
                  "name",
                  userId,
                  e.target.value
                );
                setNameStatus(e.target.value);
              }}
              lastUpdatedBy={user?.basic_details?.name_verified_by}
              lastUpdatedOn={user?.basic_details?.name_last_update_on}
              lastUpdatedAt={user?.basic_details?.name_last_update_at}
            />
            <Details
              dataId="2"
              title="PAN Verification"
              dataValue={user?.basic_details?.pan_number}
              selectedOption={panStatus}
              onChange={(e) => {
                updateStatus(
                  "updateBasicDetailsStatus",
                  "pan",
                  userId,
                  e.target.value
                );
                setPanStatus(e.target.value);
              }}
              lastUpdatedBy={user?.basic_details?.pan_verified_by}
              lastUpdatedOn={user?.basic_details?.pan_last_update_on}
              lastUpdatedAt={user?.basic_details?.pan_last_update_at}
            />
          </div>
        </div>
        <div className={verificationClassList}>
          {address ? (
            <div className={documentClassList} style={{ height: "285px" }}>
              <embed
                width="100%"
                height={address.includes("image") ? "" : "100%"}
                src={`${address}`}
              />
            </div>
          ) : (
            <div className={`w-1/2 ${documentClassList}`}> Loading...</div>
          )}
          <div className={verificationParameter}>
            <Details
              dataId="3"
              title="Address Verification"
              dataValue={user?.basic_details?.address}
              selectedOption={addressStatus}
              onChange={(e) => {
                updateStatus(
                  "updateBasicDetailsStatus",
                  "address",
                  userId,
                  e.target.value
                );
                setAddressStatus(e.target.value);
              }}
              lastUpdatedBy={user?.basic_details?.address_verified_by}
              lastUpdatedOn={user?.basic_details?.address_last_update_on}
              lastUpdatedAt={user?.basic_details?.address_last_update_at}
            />
          </div>
        </div>
        <div className={verificationClassList}>
          {bank ? (
            <div className={documentClassList} style={{ height: "285px" }}>
              <embed
                width="100%"
                height={bank.includes("image") ? "" : "100%"}
                src={`${bank}`}
              />
            </div>
          ) : (
            <div className={`w-1/2 ${documentClassList}`}> Loading...</div>
          )}
          <div className={verificationParameter}>
            <Details
              dataId="4"
              title="Bank Verification"
              dataValue={user?.bank_details?.bank_name}
              selectedOption={bankStatus}
              onChange={(e) => {
                updateStatus(
                  "updateBankDetailsStatus",
                  "bank_details",
                  userId,
                  e.target.value
                );
                setBankStatus(e.target.value);
              }}
              lastUpdatedBy={user?.bank_details?.verified_by}
              lastUpdatedOn={user?.bank_details?.last_update_on}
              lastUpdatedAt={user?.bank_details?.last_update_at}
            />
          </div>
        </div>
        <div className={verificationClassList}>
          {demat ? (
            <div className={documentClassList} style={{ height: "285px" }}>
              <embed
                width="100%"
                height={demat.includes("image") ? "" : "100%"}
                src={`${demat}`}
              />
            </div>
          ) : (
            <div className={`w-1/2 ${documentClassList}`}> Loading...</div>
          )}
          <div className={verificationParameter}>
            <Details
              dataId="5"
              title="Demat Verification"
              dataValue={user?.demat_details?.dp_id}
              selectedOption={dematStatus}
              onChange={(e) => {
                updateStatus(
                  "updateDematDetailsStatus",
                  "demat_details",
                  userId,
                  e.target.value
                );
                setDematStatus(e.target.value);
              }}
              lastUpdatedBy={user?.demat_details?.verified_by}
              lastUpdatedOn={user?.demat_details?.last_update_on}
              lastUpdatedAt={user?.demat_details?.last_update_at}
            />
          </div>
        </div>
      </div>
      <div className="mt-2">
        <textarea
          name=""
          className="w-full border h-20 p-2"
          placeholder="Add Remark"
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
        ></textarea>
        {error !== "" && <div className="text-red-500 my-2">{error}</div>}
        {notification !== "" && (
          <div className="text-green-500 my-2">{notification}</div>
        )}
        <button
          className="flex items-center p-2 bg-blue-700 text-white justify-center text-center border google-btn rounded-full px-12 bg-gray-200 shadow focus:outline-none"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
