import React from "react";
import { useAdminLogin } from "../contexts/AdminLoginContext";
import ProfileModal from "../components/ProfileModal";

export default function AdminNavbar() {
  const { adminLogout } = useAdminLogin();
  return (
    <nav
      className="border-b shadow w-full overflow-scroll no-scrollbar"
      style={{ height: "10vh" }}
    >
      <ul className="flex items-center justify-between h-full px-4">
        <img
          className=" w-24  float-right  cursor-pointer "
          src="https://altiusinvestech.com/photos/logo.png"
          alt="Altius Logo"
        />
        <div className="flex items-center ">
          <li className="mx-4 font-medium text-gray-al-100 cursor-pointer">
            Companies
          </li>
          <li className="mx-4 font-medium text-gray-al-100 cursor-pointer">
            Holdings
          </li>
          <li className="mx-4 font-medium text-gray-al-100 cursor-pointer">
            Order Book
          </li>
          <li className="ml-8 cursor-pointer">
            <ProfileModal logout={adminLogout} />
          </li>
        </div>
      </ul>
    </nav>
  );
}
