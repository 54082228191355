import React from "react";
import ProfileModal from "./ProfileModal";
import { useLogin } from "../contexts/LoginContext";

export default function Navbar() {
  const { logout } = useLogin();
  return (
    <nav
      className="border-b shadow bg-white sticky top-0 z-50 w-full"
      style={{ height: "10vh" }}
    >
      <ul className="flex items-center justify-between h-full px-4">
        <img
          className=" w-24  float-right  cursor-pointer "
          src="https://altiusinvestech.com/photos/logo.png"
          alt="Altius Logo"
        />
        <div className="flex items-center justify-between">
          <span className=" mx-4 md:flex hidden text-lg font-medium text-gray-al-100 cursor-pointer">
            Companies
          </span>
          <span className=" mx-4 md:flex hidden text-lg font-medium text-gray-al-100 cursor-pointer">
            Holdings
          </span>
          <span className=" mx-4 md:flex hidden text-lg font-medium text-gray-al-100 cursor-pointer">
            Order Book
          </span>
          <span className="mx-2  md:hidden flex  font-medium text-blue-al cursor-pointer">
            <i class="fas fa-eye"></i>
          </span>

          <span className="mx-2  md:hidden flex   font-medium text-blue-al cursor-pointer">
            <i class="fas fa-plus-circle"></i>
          </span>
          <span className="cursor-pointer">
            <ProfileModal logout={logout} />
          </span>
        </div>
      </ul>
    </nav>
  );
}
