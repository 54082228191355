import React, { useState, useRef } from "react";
import axios from "axios";
import { useLogin } from "../contexts/LoginContext";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const Upload = ({ uploadFile, uploadPassword, file }) => {
  const [fileName, setFileName] = useState("");
  const ref = useRef();

  const openFile = () => {
    ref.current.click();
  };

  let handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (
      file.size <= 2000000 &&
      (file.type === "image/jpeg" ||
        file.type === "application/pdf" ||
        file.type === "image/png")
    ) {
      uploadFile(file);
      setFileName(file.name);
    } else {
      alert("Please only upload a pdf/jpeg file of <= 2MB");
    }
    // console.log("Hello", file);
    // if(file.size > )
  };

  return (
    <>
      <div className="p-2 py-4 mt-2 flex flex-col flex-between rounded-lg border-dashed border-4 border-gray-200 bg-white mr-2">
        <i className="fa fa-upload fa-2x text-center text-gray-500 mt-1 mb-1"></i>

        <div
          className={`flex ${
            fileName || file ? "justify-between" : "justify-center"
          } text-sm text-gray-400`}
        >
          {fileName !== "" ? (
            <span className="text-blue-al">{fileName}</span>
          ) : file !== null ? (
            <span className="text-blue-al">File Uploaded</span>
          ) : null}
          <span>
            <span className="text-blue-al cursor-pointer" onClick={openFile}>
              Click Here
            </span>{" "}
            <input
              className="hidden"
              type="file"
              ref={ref}
              onChange={handleFileUpload}
            ></input>
            to choose.
          </span>
        </div>
      </div>

      <div className="flex justify-between m-2">
        <span className="text-sm text-gray-500">
          If the file is protected please provide password{" "}
        </span>
        <input
          type="text"
          className="bg-white ml-1 text-gray-600 p-2 h-8"
          placeholder="Enter Password"
          onChange={(e) => uploadPassword(e.target.value)}
        ></input>
      </div>
    </>
  );
};

export default function DocumenntUpload({ setPageNumber }) {
  const [panCardFile, setPanCardFile] = useState(null);
  const [addressFile, setAddressFile] = useState(null);
  const [dematFile, setDematFile] = useState(null);
  const [bankFile, setBankFile] = useState(null);
  const [warning, setWarning] = useState("");

  const [bankPassword, setBankPassword] = useState(null);
  const [panCardPassword, setPanCardPassword] = useState(null);
  const [addressPassword, setAddressPassword] = useState(null);
  const [dematPassword, setDematPassword] = useState(null);

  const { token } = useLogin();
  const history = useHistory();
  setPageNumber(3);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleFilesUpload = async () => {
    if (
      panCardFile !== null &&
      addressFile !== null &&
      dematFile !== null &&
      bankFile !== null
    ) {
      setWarning("");
      const formData = new FormData();
      formData.append("address_proof", addressFile);
      formData.append("bank_proof", bankFile);
      formData.append("demat_proof", dematFile);
      formData.append("pan_card", panCardFile);
      formData.append("address_proof_password", addressPassword);
      formData.append("bank_proof_password", bankPassword);
      formData.append("demat_proof_password", dematPassword);
      formData.append("pan_card_password", panCardPassword);

      await axios
        .post(
          "https://webdev.milindsharma.com/user/kyc/uploadDocuments",
          formData,
          config
        )
        .then((res) => {
          history.push("/kyc/reviewDetails");
        })
        .catch((err) => {
          console.log(err);
          setWarning("File Upload Error, Try Again");
        });
    } else {
      setWarning("Please upload all Documents");
    }
  };
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get(
          `https://webdev.milindsharma.com/user/kyc/document/address_proof`,
          config
        );
        setAddressFile(res?.data);
      } catch (err) {
        console.log("error", err?.response?.data?.error);
      }

      try {
        let res = await axios.get(
          `https://webdev.milindsharma.com/user/kyc/document/bank_proof`,
          config
        );
        setBankFile(res?.data);
      } catch (err) {
        console.log("error", err?.response?.data?.error);
      }

      try {
        let res = await axios.get(
          `https://webdev.milindsharma.com/user/kyc/document/demat_proof`,
          config
        );
        setDematFile(res?.data);
      } catch (err) {
        console.log("error", err?.response?.data?.error);
      }

      try {
        let res = await axios.get(
          `https://webdev.milindsharma.com/user/kyc/document/pan_card`,
          config
        );
        setPanCardFile(res?.data);
      } catch (err) {
        console.log("error", err?.response?.data?.error);
      }
    })();
  }, []);

  return (
    <div className="flex flex-col flex-1 h-full overflow-scroll no-scrollbar">
      <div className="text-xl font-medium text-blue-al">Upload Documents</div>

      <div className="flex flex-col justify-between">
        <div className="mt-2 ">
          <label className="text-gray-600 font-medium text-xs mt-2 mb-2 w-full truncate ...">
            Demat Account (DIS booklist,screenshot showing account name,DP Id
            and Client Id )
          </label>
          <Upload
            uploadFile={setDematFile}
            uploadPassword={setDematPassword}
            file={dematFile}
          />
        </div>

        <div className="mt-2 ">
          <label className="text-gray-600 font-medium text-xs mt-2 mb-2">
            Address Proof (Driving license/Passport/Voter Id)
          </label>
          <Upload
            uploadFile={setAddressFile}
            uploadPassword={setAddressPassword}
            file={addressFile}
          />
        </div>

        <div className="mt-2 ">
          <label className="text-gray-600 font-medium text-xs mt-2 mb-2">
            PAN Card
          </label>
          <Upload
            uploadFile={setPanCardFile}
            uploadPassword={setPanCardPassword}
            file={panCardFile}
          />
        </div>

        <div className="mt-2 ">
          <label className="text-gray-600 font-medium text-xs mt-2 mb-2">
            Bank (Passbook/Cancelled Cheque/Screenshot of login - Evidencing
            bank account No. and IFSC code)
          </label>
          <Upload
            uploadFile={setBankFile}
            uploadPassword={setBankPassword}
            file={bankFile}
          />
        </div>
      </div>
      <div>
        {warning && <div className="text-red-500 mb-2">{warning}</div>}
        <button
          className="border bg-blue-al rounded-lg items-center justify-center text-white px-4 py-2 cursor-pointer focus:outline-none shadow"
          onClick={handleFilesUpload}
        >
          Next Step &gt;
        </button>
      </div>
    </div>
  );
}
