import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useLogin } from "../contexts/LoginContext";
import axios from "axios";

export default function DematDetails({ setPageNumber }) {
  const [warning, setWarning] = useState(false);
  const [dpId, setDpId] = useState("");
  const [clientId, setClientId] = useState("");

  const { token } = useLogin();
  const inputClassList =
    "border-2 rounded px-2 w-4/6 py-1 my-1 mb-2 focus:outline-none";
  let history = useHistory();
  setPageNumber(1);

  function validateDpId(dpId) {
    const re = /\b[a-zA-Z0-9]{8}\b/;
    let res = re.test(dpId);
    if (res !== true) setWarning("DP Id must be 8 digit Alpha Numeric");

    return res;
  }

  function validateClientId(clientId) {
    const re = /^\d{8}$/;
    let res = re.test(clientId);
    if (res !== true) setWarning("Client Id must be 8 digit Numeric");

    return res;
  }

  let handleClick = async () => {
    const isClientIdValid = validateClientId(clientId);
    const isDpIdValid = validateDpId(dpId);

    if (isDpIdValid && isClientIdValid) {
      setWarning(false);
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const bodyParameters = {
        dp_id: dpId,
        client_id: clientId,
      };
      console.log(dpId, clientId);
      await axios
        .post(
          "https://webdev.milindsharma.com/user/kyc/updateDematDetails",
          bodyParameters,
          config
        )
        .then((res) => {
          history.push(`bankDetails`);
        })
        .catch((err) => setWarning(err.response.data.error));
    }
  };

  useEffect(() => {
    (async function () {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        let res = await axios.get(
          "https://webdev.milindsharma.com/user/kyc",
          config
        );
        setDpId(res?.data?.demat_details?.dp_id || "");
        setClientId(res?.data?.demat_details?.client_id || "");
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div className="flex flex-col flex-1 h-full ml-5 overflow-scroll no-scrollbar">
      <div className="text-xl font-medium text-blue-al">Demat Details</div>

      <div className="flex flex-col">
        <label className="text-sm mt-24 mb-2" htmlFor="dpId">
          DP Id
        </label>
        <input
          className={inputClassList}
          type="text"
          id="dpId"
          value={dpId}
          onChange={(e) => setDpId(e.target.value)}
        ></input>
        <label className="text-sm mt-8 mb-2" htmlFor="clientId">
          Client Id
        </label>
        <input
          className={inputClassList}
          type="text"
          id="clientId"
          value={clientId}
          onChange={(e) => setClientId(e.target.value)}
        ></input>
      </div>
      <div className="mt-8">
        {warning && <div className="text-red-500 mb-2">{warning}</div>}
        <button
          className="border bg-blue-al rounded-lg items-center justify-center text-white px-4 py-2 cursor-pointer focus:outline-none shadow"
          onClick={handleClick}
        >
          Next Step &gt;
        </button>
      </div>
    </div>
  );
}
