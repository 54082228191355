import React from "react";
import axios from "axios";
import { createContext, useContext, useState } from "react";

export const LoginContext = createContext();

function loginService(username, password) {
  return axios.post("https://webdev.milindsharma.com/user/login", {
    username,
    password,
  });
}

export function LoginProvider({ children }) {
  const { isUserLoggedIn, token: savedToken } =
    JSON.parse(localStorage?.getItem("login")) || {};

  const [login, setLogin] = useState(isUserLoggedIn);
  const [token, setToken] = useState(savedToken);
  const [loginError, setLoginError] = useState("");

  function loginUserWithToken(token) {
    setLogin(true);
    setToken(token);
    console.log({ token });
    const time = Date.now();
    localStorage.setItem(
      "login",
      JSON.stringify({ isUserLoggedIn: true, token, time })
    );
  }

  async function loginUserWithCredentials(username, password) {
    try {
      const res = await loginService(username, password);
      console.log({ res });
      if (res.status === 200) {
        loginUserWithToken(res.data.token);
      }
    } catch (err) {
      console.error(err?.response?.data);
      setLoginError(err?.response?.data.error);
    }
  }

  function logout() {
    localStorage.removeItem("login");
    setLogin(false);
    setToken(null);
  }

  return (
    <LoginContext.Provider
      value={{
        login,
        loginUserWithCredentials,
        setLogin,
        logout,
        token,
        loginError,
        loginUserWithToken,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}

export function useLogin() {
  const { time } = JSON.parse(localStorage?.getItem("login")) || {};
  const currentTime = Date.now();
  if (currentTime - time > 2 * 60 * 60 * 1000) {
    localStorage.removeItem("login");
  }

  return useContext(LoginContext);
}
