import axios from "axios";
import React, { useState } from "react";
import { useLogin } from "../contexts/LoginContext";

const style = {
  height: "40vh",
};

const rowClass = "flex justify-between text-gray-700 border-b py-2";

function FindStatus({ status }) {
  let statusClass;
  if (status === "rejected") statusClass = "text-red-500";
  else if (status === "verified") statusClass = "text-green-500";
  else statusClass = "text-gray-500";

  return <span className={statusClass}>{status}</span>;
}

export default function TermsConditions({ resObj }) {
  const { token } = useLogin();
  const [isChecked, setIsChecked] = useState(false);

  let handleFinalSubmit = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyPayload = {};

    console.log("tnc", token);
    await axios
      .post(
        "https://webdev.milindsharma.com/user/kyc/reviewAndSubmit",
        bodyPayload,
        config
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err?.response));
  };

  return (
    <div className="md:w-1/4">
      <div
        className="p-4 m-4 bg-gray-100 rounded-lg flex flex-col justify-between"
        style={style}
      >
        <div className="">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => setIsChecked((p) => !p)}
          ></input>
          <label> Terms and Conditions</label>
        </div>
        <div className="flex flex-col justify-between">
          <div className="mb-4">
            <h3>Declaration</h3>
          </div>
          <div className="flex text-white">
            <button
              className={`${
                isChecked ? "bg-blue-al" : "bg-gray-400"
              } py-1 md:px-6 px-2 rounded-lg shadow focus:outline-none`}
              disabled={!isChecked}
              onClick={handleFinalSubmit}
            >
              Final Submit
            </button>
          </div>
        </div>
      </div>
      <div className="p-6">
        <div className={rowClass}>
          <span>Name</span>
          <FindStatus status={resObj?.basic_details?.name_status} />
        </div>
        <div className={rowClass}>
          <span>Pan Card</span>
          <FindStatus status={resObj?.basic_details?.pan_status} />
        </div>
        <div className={rowClass}>
          <span>Address</span>
          <FindStatus status={resObj?.basic_details?.address_status} />
        </div>
        <div className={rowClass}>
          <span>Bank Details</span>
          <FindStatus status={resObj?.bank_details?.status} />
        </div>
        <div className={rowClass}>
          <span>Demat Details</span>
          <FindStatus status={resObj?.demat_details?.status} />
        </div>
      </div>
      {resObj?.remark && (
        <div className="p-4 bg-gray-100 rounded-lg m-4">
          <label>Remark :-</label>
          <span>{resObj?.remark}</span>
        </div>
      )}
    </div>
  );
}
