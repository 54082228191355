import React from "react";
import { Switch, Route } from "react-router-dom";
import AdminLoginPage from "./AdminLoginPage";
import { AdminPrivateRoute } from "../AdminPrivateRoute";
import AdminKycHome from "./AdminKycHome";

export default function AdminHome() {
  return (
    <div>
      <Switch>
        <Route exact path="/admin">
          <AdminLoginPage />
        </Route>
        <AdminPrivateRoute path="/admin/:path">
          <AdminKycHome />
        </AdminPrivateRoute>
      </Switch>
    </div>
  );
}
