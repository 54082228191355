import React from "react";
import "./styles.css";
import "./styles/tailwind-pre-build.css";
import Navbar from "./components/Navbar";
import SignUp from "./components/SignUp";
import LoginPage from "./components/LoginPage";
import Kyc from "./components/kyc/Kyc";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import KycHome from "./components/kyc/KycHome";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import { ForgotPasswordRoute } from "./ForgotPasswordRoute";
import AdminHome from "./admin/AdminHome";

export default function App() {
  return (
    <div className="flex flex-col justify-between h-screen">
      <Switch>
        <ForgotPasswordRoute path="/admin">
          <AdminHome />
        </ForgotPasswordRoute>

        <Route exact path={["/login", "/"]}>
          <LoginPage />
        </Route>

        <ForgotPasswordRoute path="/signup">
          <SignUp />
        </ForgotPasswordRoute>

        <ForgotPasswordRoute path="/forgotPassword">
          <ForgotPassword />
        </ForgotPasswordRoute>

        <PrivateRoute path="/kyc">
          <KycHome />
        </PrivateRoute>
      </Switch>
    </div>
  );
}
