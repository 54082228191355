import { Route, Redirect } from "react-router-dom";
import React from "react";
import { useAdminLogin } from "./contexts/AdminLoginContext";

export function AdminPrivateRoute({ children, path, ...rest }) {
  const { adminLogin } = useAdminLogin();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        adminLogin ? (
          children
        ) : (
          <Redirect to={{ pathname: "/admin", state: { from: location } }} />
        )
      }
    />
  );
}
