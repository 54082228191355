import React from "react";
import EmailForgotPassword from "./EmailForgotPassword";
import VerifyOtp from "./VerifyOtp";
import ResetPassword from "./ResetPassword";
import ForgotPasswordStep from "./ForgotPasswordStep";
import { Switch, Route, Router } from "react-router-dom";
import ReviewSubmit from "../ReviewSubmit";

export default function ForgotPassword() {
  return (
    <div>
      <div className="flex flex-col justify-between h-screen">
        <Switch>
          <Route exact path={["/forgotPassword", "/"]}>
            <EmailForgotPassword />
          </Route>
          <Route path="/forgotPassword/:forgotPasswordStep">
            <ForgotPasswordStep />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
