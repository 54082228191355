import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory, useLocation } from "react-router-dom";

export default function SignUpOne() {
  const [googleUrl, setGoogleUrl] = useState("");
  const [warning, setWarning] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [email, setEmail] = useState("");

  const [nameWarning, setNameWarning] = useState(false);
  const [phoneNumberWarning, setPhoneNumberWarning] = useState(false);
  const [emailWarning, setEmailWarning] = useState(false);

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  console.log(searchParams.get("redirect_uri"));

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(search);
      const config = {
        headers: { Authorization: `Bearer null` },
      };
      const googleCode = searchParams.get("code");

      if (googleCode) {
        console.log(googleCode);

        const bodyParameters = {
          code: googleCode,
        };

        try {
          let res = await axios.post(
            `https://webdev.milindsharma.com/user/registration/google`,
            bodyParameters,
            config
          );
          console.log("Hello", res);
          localStorage.setItem("googleAuthRes", JSON.stringify(res?.data));
        } catch (err) {
          console.log("error", err?.response);
        }
      }
    })();
  }, []);

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let res = re.test(String(email).toLowerCase());
    if (res !== true) setEmailWarning(true);
    else setEmailWarning(false);
    return res;
  }

  function validatePhoneNumber(phoneNumber) {
    const re = /^\d{10}$/;
    let res = re.test(phoneNumber);
    if (res !== true) setPhoneNumberWarning(true);
    else setPhoneNumberWarning(false);
    return res;
  }

  function validateName(name) {
    let res = name.length > 0;
    if (res !== true) setNameWarning(true);
    else setNameWarning(false);
    return res;
  }

  useEffect(() => {
    (async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer null` },
        };
        let res = await axios.get(
          `https://webdev.milindsharma.com/user/registration/googleAuthURL`,
          config
        );
        setGoogleUrl(res?.data?.authURL);
        console.log("Hello", res?.data?.authURL);
      } catch (err) {
        console.log("error", err?.response);
      }
    })();
  }, []);

  let handleClick = async () => {
    const isEmailValid = validateEmail(email);
    const isPhoneNumberValid = validatePhoneNumber(phoneNumber);
    const isNameValid = validateName(name);

    if (isEmailValid && isPhoneNumberValid && isNameValid) {
      setWarning(false);
      await axios
        .post(
          "https://webdev.milindsharma.com/user/registration/saveBasicDetails",
          {
            name: name,
            mobile: phoneNumber,
            email: email,
          }
        )
        .then((res) => {
          localStorage.setItem("basicDetailToken", res.data.token);
          history.push(`signup/otp`);
          console.log({ res });
        })
        .catch((err) => {
          if (err.response) {
            setWarning(err.response.data.error);
            console.log(err.response.data);
          }
        });
    }
  };

  return (
    <>
      <div className="flex flex-col justify-between h-screen mb-12 overflow-scroll no-scrollbar justify-center ">
        <div className="">
          <img
            className="md:w-32 w-24"
            src="https://altiusinvestech.com/photos/logo.png"
            alt="Altius Logo"
          />
        </div>
        <div>
          <h1 className="font-bold text-xl text-gray-700">Create an account</h1>
          <p className="text-gray-500">Let's get started</p>
        </div>
        <div action="" className="flex flex-col justify-around">
          <input
            className="outline-none md:py-6 py-4 border-b focus:border-blue-700"
            type="name"
            name="name"
            id="name"
            placeholder="Name"
            required
            onChange={(e) => setName(e.target.value)}
          />
          {nameWarning && (
            <div className="text-red-500">Enter a valid Name</div>
          )}
          <input
            className="outline-none md:py-6 py-4 border-b focus:border-blue-700"
            type="email"
            name="email"
            id="email"
            placeholder="Email Address"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailWarning && (
            <div className="text-red-500">Enter a valid Email</div>
          )}
          <input
            className="outline-none md:py-6 py-4 border-b focus:border-blue-700"
            type="number"
            name="phoneNumber"
            id="phoneNumber"
            placeholder="Mobile Number"
            required
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          {phoneNumberWarning && (
            <div className="text-red-500 w-min">
              Enter a valid 10 digit Phone Number without any prefixes.
            </div>
          )}
        </div>

        {warning && <div className="text-red-500">{warning}</div>}

        <div className="flex justify-center">
          <button
            className="flex items-center bg-blue-700 text-white justify-center text-center border google-btn rounded-full px-12 bg-gray-200 shadow focus:outline-none"
            onClick={handleClick}
          >
            <p className="px-1 py-2">Send OTP</p>
          </button>
        </div>

        <div className="flex items-center w-full">
          <hr className="border w-1/4 md:1/2 h-0" />
          <div className="text-xs md:w-4/12 w-1/2 overflow-scroll no-scrollbar text-center">
            or Sign up with Google
          </div>
          <hr className="border w-1/4 md:1/2 h-0" />
        </div>

        <div className="flex justify-center">
          <a
            href={googleUrl}
            className="flex items-center justify-center text-center border google-btn rounded-full px-12 bg-gray-200 shadow focus:outline-none"
          >
            <img
              className="w-10 h-10 p-2"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/157px-Google_%22G%22_Logo.svg.png"
              alt="Google Logo"
            />
            <p className="px-1 py-2">Google</p>
          </a>
        </div>

        <div className="text-blue-600 mt-2">
          Already Have an Account?{" "}
          <a
            className="underline cursor-pointer"
            onClick={() => {
              window.location.href = "/login";
            }}
          >
            Sign In
          </a>
        </div>
      </div>
    </>
  );
}
