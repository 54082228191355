import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useLogin } from "../contexts/LoginContext";

export default function SignUpTwo() {
  const [password, setPassword] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState(0);
  const [error, setError] = useState(false);
  const { loginUserWithToken } = useLogin();

  let handleClick = async () => {
    if (password === confirmPassword) {
      setError(false);
      let token = localStorage.getItem("otpToken");

      await axios
        .post(
          "https://webdev.milindsharma.com/user/registration/saveUserPassword",
          {
            token: token,
            password: password,
            confirm_password: confirmPassword,
          }
        )
        .then((res) => {
          console.log({ res });
          loginUserWithToken(res?.data?.token);
          window.location.href = "/kyc/basicProfile";
        })
        .catch((err) => {
          if (err.response) setError(err.response.data.error);
        });
    } else {
      setError("Passwords don't match");
    }
  };

  return (
    <>
      <div className=" flex flex-col justify-around  m-8 mb-32 h-screen  overflow-scroll no-scrollbar ">
        <div className="flex ">
          <img
            className="md:w-32 w-24 "
            src="https://altiusinvestech.com/photos/logo.png"
            alt="Altius Logo"
          />
        </div>
        <div className="w-full flex flex-col justify-between mr-4">
          <input
            className="outline-none md:py-6 py-4 border-b focus:border-blue-700"
            type="password"
            placeholder="New Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            className="outline-none md:py-6 py-4 border-b focus:border-blue-700"
            type="password"
            placeholder="Confirm New Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {error && <div className="text-red-500 w-min">{error}</div>}
          <div className="flex justify-center items-center">
            <button
              className="flex items-center p-2 mt-4 bg-blue-700 text-white justify-center text-center border google-btn rounded-full px-12 bg-gray-200 shadow focus:outline-none"
              onClick={handleClick}
            >
              Save Password
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

// {
//     "request":"saveUserPassword",
//     "token":"cFY4OWRQRzFYU3VqWXg3SnFFelFnUT09",
//     "password": "123123123",
//     "confirm_password": "123123123"
// }
