import React from "react";

export default function BottomBarMobile() {
	return (
		<>
			<ul className="border text-gray-500 bg-gray-100 flex md:hidden sticky bottom-0 items-center justify-between  px-4">
				<li className="flex flex-col  items-center p-2    font-medium  cursor-pointer">
					<i class="fa fa-building" aria-hidden="true"></i>
					<span className="text-xs">Companies</span>
				</li>
				<li className="w-1/3 flex flex-col border-l-2 border-r-2 items-center p-2   font-medium cursor-pointer">
					<i class="fas fa-hand-holding-usd"></i>
					<span className="text-xs">Holdings</span>
				</li>
				<li className="flex flex-col items-center  p-2    font-medium  cursor-pointer">
					<i class="fas fa-book-reader"></i>
					<span className="text-xs">Order Book</span>
				</li>
			</ul>
		</>
	);
}
