import { Route, Redirect } from "react-router-dom";
import React from "react";
import { useLogin } from "./contexts/LoginContext";

export function PrivateRoute({ children, path, ...rest }) {
  const { login } = useLogin();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        login ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
}
