import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useLogin } from "../contexts/LoginContext";

export default function SignUpMobileNumber() {
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [error, setError] = useState(false);
  const history = useHistory();
  const { search } = useLocation();
  const { login, loginUserWithToken } = useLogin();

  function validatePhoneNumber(phoneNumber) {
    const re = /^\d{10}$/;
    let res = re.test(phoneNumber);

    return res;
  }

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(search);
      const config = {
        headers: { Authorization: `Bearer null` },
      };
      const googleCode = searchParams.get("code");

      if (googleCode) {
        console.log(googleCode);

        const bodyParameters = {
          code: googleCode,
        };

        try {
          let res = await axios.post(
            `https://webdev.milindsharma.com/user/registration/google`,
            bodyParameters,
            config
          );
          console.log("Hello", res);
          localStorage.setItem("googleAuthRes", JSON.stringify(res?.data));
        } catch (err) {
          console.log("error", err?.response);
          setError(err?.response?.data?.error);
        }
      }
    })();
  }, []);

  let handleClick = async () => {
    const isPhoneNumberValid = validatePhoneNumber(phoneNumber);

    if (isPhoneNumberValid) {
      let res = JSON.parse(localStorage.getItem("googleAuthRes"));
      console.log({ res });
      setError(false);
      const bodyParams = {
        token: res?.token,
        name: res?.name,
        mobile: phoneNumber,
      };

      console.log({ bodyParams });
      await axios
        .post(
          "https://webdev.milindsharma.com/user/registration/googleSaveMobile",
          bodyParams
        )
        .then((res) => {
          console.log({ res });
          if (!login) {
            loginUserWithToken(res?.data?.token);
          }
          history.push("/login");
        })
        .catch((err) => {
          if (err.response) setError(err.response.data.error);
        });
    } else {
      setError("Enter Valid Mobile Number");
    }
  };

  return (
    <>
      <div className=" flex flex-col justify-around  m-8 mb-32 h-screen  overflow-scroll no-scrollbar ">
        <div className="flex ">
          <img
            className="md:w-32 w-24 "
            src="https://altiusinvestech.com/photos/logo.png"
            alt="Altius Logo"
          />
        </div>
        <div className="w-full flex flex-col justify-between mr-4">
          <input
            className="outline-none md:py-6 py-4 border-b focus:border-blue-700"
            type="number"
            name="phoneNumber"
            id="phoneNumber"
            placeholder="Mobile Number"
            required
            onChange={(e) => setPhoneNumber(e.target.value)}
          />

          {error && <div className="text-red-500 w-min">{error}</div>}
          <div className="flex justify-center items-center">
            <button
              className="flex items-center p-2 mt-4 bg-blue-700 text-white justify-center text-center border google-btn rounded-full px-12 bg-gray-200 shadow focus:outline-none"
              onClick={handleClick}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
