import React, { useState } from "react";
import SearchField from "./SearchField";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import "../styles.css";

let apps = [
	{
		name: "UPSTOX",
	},
	{
		name: "ZERODHA",
	},
	{
		name: "GROWW",
	},
];

export default function Sidebar() {
	const [selected, setSelected] = useState("UPSTOX");
	return (
		<div className="lg:w-1/4 hidden border-l md:flex flex-col justify-between border-r overflow-hidden">
			<div>
				<SearchField placeholder="Search Eg. Zerodha, Groww, Upstox" />
				<ul>
					{apps.map((item, i) => (
						<li key={i} className="flex justify-between p-3 border-b text-sm">
							<div className="overflow-scroll w-15 no-scrollbar">
								{item.name}
							</div>
							<div className="flex items-center">
								<button className="text-red-500 mx-2">Buy</button>
								<button className="text-green-500 mx-2">Sell</button>
								{selected === item.name ? (
									<BsBookmarkFill
										className="mx-2"
										onClick={() => {
											setSelected(item.name);
										}}
									/>
								) : (
									<BsBookmark
										className="mx-2"
										onClick={() => {
											setSelected(item.name);
										}}
									/>
								)}
							</div>
						</li>
					))}
				</ul>
			</div>
			<div className="flex bg-gray-300 justify-around py-2 text-sm">
				<button className="border-r border-gray-500 w-1/2">
					All Companies
				</button>
				<button className="w-1/2 items-center">Watchlist</button>
			</div>
		</div>
	);
}
