import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { LoginProvider } from "./contexts/LoginContext";
import { AdminLoginProvider } from "./contexts/AdminLoginContext";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <LoginProvider>
    <AdminLoginProvider>
      <Router>
        <App />
      </Router>
    </AdminLoginProvider>
  </LoginProvider>,

  rootElement
);
