import React, { useState } from "react";
import BasicProfile from "./BasicProfile";
import DematDetails from "../DematDetails";
import DocumentUpload from "../DocumentUpload";
import BankDetails from "./BankDetails";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";

let userKycSteps = [
  {
    title: "Your Basic Profile",
    description: "Name, Email, Mobile, Address, PAN no.",
    path: "/kyc/basicProfile",
  },
  {
    title: "Demat Details",
    description: "DPI Id, Client Id",
    path: "/kyc/dematDetails",
  },
  {
    title: "Bank Details",
    description: "Bank Name, Bank Account No., IFSC Code",
    path: "/kyc/bankDetails",
  },
  {
    title: "Upload Documents",
    description:
      "PAN Card, Address Proof, Bank Cheque/Passbook, DIS Booklet/Screenshot of Name, DP Id, Client Id",
    path: "/kyc/uploadDocuments",
  },
  {
    title: "Review and Submit",
    description: "Review your Details and Submit",
    path: "/kyc/reviewDetails",
  },
];

export default function Kyc({
  setPageNumber,
  pageNumber,
  setShowStep,
  showStep,
}) {
  const history = useHistory();
  return (
    <div className="w-full flex">
      <div
        className={`md:w-1/2 ${
          showStep ? "hidden" : "flex"
        } md:flex flex-col md:items-stretch items-center p-2 `}
      >
        <div className="text-gray-500 md:my-4 my-2 sticky">
          Know Your Customer
        </div>
        <div className="overflow-y-scroll no-scrollbar">
          {userKycSteps.map((kycStep, i) => {
            let isSelected = pageNumber === i;
            return (
              <div
                className="w-full flex mb-2 cursor-pointer"
                key={i}
                onClick={() => {
                  setShowStep(true);
                  history.push(kycStep?.path);
                }}
              >
                <div
                  className={`border p-2 rounded-full h-10 w-10 flex justify-center items-center font-medium  ${
                    isSelected ? "bg-blue-al text-white" : "bg-gray-200"
                  }`}
                >
                  <span>{i + 1}</span>
                </div>
                <div className="ml-4 w-full">
                  <div
                    className={`text-lg text-gray-700 font-medium ${
                      isSelected ? "text-blue-al" : ""
                    }`}
                  >
                    {kycStep.title}
                  </div>
                  <div className="text-xs text-gray-600 pb-2">
                    {kycStep.description}
                  </div>
                  <hr className="border w-full h-0" />
                </div>
              </div>
            );
          })}
        </div>
        <span>
          <button
            className="border sticky bg-blue-al md:hidden rounded-lg items-center justify-center text-white px-4 py-2 cursor-pointer focus:outline-none shadow"
            onClick={() => setShowStep(true)}
          >
            Start Kyc
          </button>
        </span>
      </div>
      <div
        className={`md:w-1/2 w-full border-l sm:flex flex-col p-2 ${
          showStep ? "" : "hidden"
        } bg-gray-100`}
      >
        <Switch>
          <Route exact path="/kyc/dematDetails">
            <DematDetails
              setPageNumber={setPageNumber}
              setShowStep={setShowStep}
            />
          </Route>
          <Route exact path="/kyc/basicProfile">
            <BasicProfile
              setPageNumber={setPageNumber}
              setShowStep={setShowStep}
            />
          </Route>
          <Route exact path="/kyc/bankDetails">
            <BankDetails
              setPageNumber={setPageNumber}
              setShowStep={setShowStep}
            />
          </Route>
          <Route exact path="/kyc/uploadDocuments">
            <DocumentUpload
              setPageNumber={setPageNumber}
              setShowStep={setShowStep}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
