import axios from "axios";
import React, { useState, useEffect } from "react";
import GoogleLogin from "react-google-login";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useLogin } from "../contexts/LoginContext";

export default function LoginPage() {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [googleUrl, setGoogleUrl] = useState("");
  const [error, setError] = useState("");
  const { login, loginUserWithCredentials, loginError, loginUserWithToken } =
    useLogin();

  const history = useHistory();

  const { search } = useLocation();

  let handleClick = async () => {
    console.log(userId, password);
    if (!login) {
      await loginUserWithCredentials(userId, password);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer null` },
        };
        let res = await axios.get(
          `https://webdev.milindsharma.com/user/googleAuthURL`,
          config
        );
        setGoogleUrl(res?.data?.authURL);
      } catch (err) {
        console.log("error", err?.response);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(search);
      const config = {
        headers: { Authorization: `Bearer null` },
      };
      const googleCode = searchParams.get("code");

      if (googleCode) {
        console.log(googleCode);

        const bodyParameters = {
          code: googleCode,
        };

        try {
          let res = await axios.post(
            `https://webdev.milindsharma.com/user/login/google`,
            bodyParameters,
            config
          );
          console.log("Hello", res);
          if (!login) {
            loginUserWithToken(res?.data?.token);
          }
        } catch (err) {
          console.log("error", err?.response);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (login) history.push("/kyc/basicProfile");
  }, [login]);

  return (
    <div className="h-screen  flex  ">
      <div className="w-1/2 md:flex hidden">
        <img
          className="h-full w-full"
          src="https://source.unsplash.com/800x748/?buildings"
          alt=""
        />
      </div>
      <div
        className="md:w-1/2 w-full flex overflow-hidden flex-col justify-between m-4 mb-8"
        style={{ height: "90vh" }}
      >
        <div className="">
          <img
            className="md:w-32 w-24"
            src="https://altiusinvestech.com/photos/logo.png"
            alt="Altius Logo"
          />
        </div>
        <div>
          <h1 className="font-bold text-xl text-gray-700">Login Account</h1>
          <p className="text-gray-500">Hello, Welcome back!</p>
        </div>
        <div className="">
          <input
            type="text"
            name="userId"
            id="userId"
            placeholder="User Id"
            required
            className="outline-none md:py-6 w-full py-4 border-b focus:border-blue-700"
            onChange={(e) => setUserId(e.target.value)}
          />

          <input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            required
            className="outline-none md:py-6 w-full py-4 border-b focus:border-blue-700"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="text-blue-600 items-center">
          <div className="w-full justify-center flex items-center">
            {loginError !== "" && (
              <div className="text-red-500">{loginError}</div>
            )}
          </div>
          <div className="w-full flex justify-between items-center">
            <button
              className="flex items-center md:p-2 p-1  bg-blue-700 text-white justify-center text-center border google-btn rounded-full md:px-12 px-4 bg-gray-200 shadow focus:outline-none"
              onClick={handleClick}
            >
              <span>Sign In</span>
            </button>
            <Link to="/forgotPassword" className="cursor-pointer">
              Forgot Password?
            </Link>
          </div>
        </div>
        <div className="flex items-center w-full">
          <hr className="border w-1/4 md:1/2 h-0" />
          <div className="text-xs md:w-4/12 w-1/2 overflow-scroll no-scrollbar text-center">
            or Sign in with Google
          </div>
          <hr className="border w-1/4 md:1/2 h-0" />
        </div>
        <div className="flex flex-col items-center">
          <a
            href={googleUrl}
            className="flex items-center justify-center text-center border google-btn rounded-full px-12 bg-gray-200 shadow focus:outline-none"
          >
            <img
              className="w-10 h-10 p-2"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/157px-Google_%22G%22_Logo.svg.png"
              alt="Google Logo"
            />
            <p className="px-1 py-2">Google</p>
          </a>
        </div>
        <Link to="/signup" className="text-blue-600 cursor-pointer float-left">
          Create an account
        </Link>
      </div>
    </div>
  );
}

{
  /*; */
}
