import React from "react";
import { Redirect, Route } from "react-router-dom";

export default function SignUpPrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem("basicDetailToken") ? (
          children
        ) : (
          <Redirect to={{ pathname: "/signup", state: { from: location } }} />
        )
      }
    />
  );
}
