import React from "react";
import SignUpOne from "./SignUpOne";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SignUpTwo from "./SignUpTwo";
import SignUpThree from "./SignUpThree";
import SignUpPrivateRoute from "../utils/SignUpPrivateRoute";
import SignUpMobileNumber from "../signup/SignUpMobileNumber";

export default function SignUp() {
  return (
    <div className="flex h-full">
      <div className="w-1/2 bg-red-200 h-full hidden sm:flex">
        <img
          className="h-screen w-full object-cover"
          src="https://source.unsplash.com/800x748/?buildings"
          alt=""
        />
      </div>

      <div className="sm:w-1/2 flex flex-col justify-around h-full m-4 w-full">
        <Switch>
          <Route exact path={["/signup", "/"]}>
            <SignUpOne />
          </Route>
          <SignUpPrivateRoute path="/signup/otp">
            <SignUpTwo />
          </SignUpPrivateRoute>
          <SignUpPrivateRoute path="/signup/saveUserPassword">
            <SignUpThree />
          </SignUpPrivateRoute>
          <Route exact path="/signup/mobileNumber">
            <SignUpMobileNumber />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
