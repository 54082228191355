import React from "react";
import SideBar from "../SideBar";
import Kyc from "./Kyc";
import Navbar from "../Navbar";
import ReviewSubmit from "../ReviewSubmit";
import { Switch, Route } from "react-router-dom";
import { useState } from "react";
import BottomBarMobile from "./BottomBarMobile";

export default function KycHome() {
  const [pageNumber, setPageNumber] = useState(0);
  const [showStep, setShowStep] = useState(false);

  return (
    <div className="flex flex-col justify-between h-full">
      <Navbar />
      <div className="flex" style={{ height: "90vh" }}>
        <SideBar />
        <div className="w-full flex">
          <Switch>
            <Route exact path="/kyc/reviewDetails">
              <ReviewSubmit
                setPageNumber={setPageNumber}
                setShowStep={setShowStep}
              />
            </Route>
            <Route path={["/kyc/:kycStep", "/kyc"]}>
              <Kyc
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                setShowStep={setShowStep}
                showStep={showStep}
              />
            </Route>
          </Switch>
        </div>
      </div>
      <BottomBarMobile />
    </div>
  );
}
