import React from "react";
import { BsSearch } from "react-icons/bs";
export default function SearchField({ placeholder }) {
  return (
    <div className="flex items-center py-2 px-3 border-b">
      <BsSearch />
      <input
        className="focus:outline-none text-sm w-full px-3"
        placeholder={placeholder}
      ></input>
    </div>
  );
}
