import React from "react";
import { Switch, Route } from "react-router-dom";
import KycAdminDashboard from "./KycAdminDashboard";
import { AdminPrivateRoute } from "../AdminPrivateRoute";
import ReviewKycRequest from "./ReviewKycRequest";
import KycAdminSidebar from "./KycAdminSidebar";
import AdminNavbar from "./AdminNavbar";

export default function AdminHome() {
  return (
    <div className="flex flex-col justify-between h-screen">
      <AdminNavbar />
      <div className="flex" style={{ height: "90vh" }}>
        <div className="w-1/5">
          <KycAdminSidebar />
        </div>
        <Switch>
          <AdminPrivateRoute exact path="/admin/dashboard">
            <KycAdminDashboard />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/admin/reviewKyc/:userId">
            <ReviewKycRequest />
          </AdminPrivateRoute>
        </Switch>
      </div>
    </div>
  );
}
