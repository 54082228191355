import React from "react";
import SideBar from "../SideBar";
import Navbar from "../Navbar";
import ReviewSubmit from "../ReviewSubmit";
import { Switch, Route } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import VerifyOtp from "./VerifyOtp";

export default function ForgotPasswordStep() {
  return (
    <div className="flex h-full w-full">
      <div className="w-1/2  h-screen hidden sm:flex">
        <img
          className=" w-full object-cover"
          src="https://source.unsplash.com/800x748/?buildings"
          alt=""
        />
      </div>
      <div className="md:w-1/2 w-full" style={{ height: "90vh" }}>
        <Switch>
          <Route exact path="/forgotPassword/resetPassword">
            <ResetPassword />
          </Route>
          <Route exact path="/forgotPassword/otp">
            <VerifyOtp />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
